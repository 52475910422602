<template>
  <div>
    <q-splitter
      class="full-height"
      v-model="splitterModel"
      style="height: 250px"
    >
      <template v-slot:before>
        <q-tabs v-model="tab" vertical class="text-teal">
          <q-tab
            name="所有訊息"
            class="text-deep-orange-5 full-width material-icons"
            label="所有訊息"
            >info</q-tab
          >
          <q-tab
            name="臺北時裝週"
            class="text-deep-orange-5 full-width material-icons"
            label="臺北時裝週"
            >photo_camera</q-tab
          >
          <q-tab
            name="學生創作"
            class="text-deep-orange-5 full-width material-icons"
            label="學生創作"
            >emoji_objects</q-tab
          >
          <q-tab
            name="系務活動"
            class="text-deep-orange-5 full-width material-icons"
            label="系務活動"
            >campaign</q-tab
          >
          <q-tab
            name="設計工作營"
            class="text-deep-orange-5 full-width material-icons"
            label="設計工作營"
            >psychology</q-tab
          >
          <q-tab
            name="比賽獲獎"
            class="text-deep-orange-5 full-width material-icons"
            label="比賽獲獎"
            >emoji_events</q-tab
          >
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="tab"
          animated
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel name="所有訊息"><NewsTable :tab="tab" /> </q-tab-panel>
          <q-tab-panel name="臺北時裝週"><NewsTable :tab="tab"/></q-tab-panel>
          <q-tab-panel name="學生創作"><NewsTable :tab="tab"/></q-tab-panel>
          <q-tab-panel name="系務活動"><NewsTable :tab="tab"/></q-tab-panel>
          <q-tab-panel name="設計工作營"><NewsTable :tab="tab"/></q-tab-panel>
          <q-tab-panel name="比賽獲獎"><NewsTable :tab="tab"/></q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
// @ is an alias to /src
import NewsTable from "@/components/NewsTable.vue";
import { ref } from "vue";

export default {
  setup() {
    return {
      tab: ref("所有訊息"),
      splitterModel: ref(20)
    };
  },
  components: { NewsTable }
};
</script>

<style>
.material-icons {
  font-size: 48px; /* Preferred icon size */
}
</style>
