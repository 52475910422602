<template>
  <div class="q-pa-md">
    <q-table
      grid
      :title="tab"
      :rows="rows"
      :columns="columns"
      row-key="name"
      :filter="filter"
      hide-header
      v-model:pagination="pagination"
      :rows-per-page-options="rowsPerPageOptions"
    >
      <template v-slot:top-right v-if="tab === '所有訊息'">
        <q-input
          borderless
          dense
          debounce="300"
          v-model="filter"
          placeholder="Search"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:item="props">
        <div class="q-pa-xs col-xs-12 col-sm-12 col-md-6">
          <q-card style="height: 50vh; overflow-y: auto;">
            <q-card-section class="text-center">
              {{ props.row.date }}
              <br />
              <strong>{{ props.row.post }}</strong>
            </q-card-section>
            <q-separator />
            <q-card-section class="q-pt-none">
              <div v-html="props.row.description"></div>
            </q-card-section>
          </q-card>
        </div>
      </template>
    </q-table>
  </div>
</template>

<script>
import { useQuasar } from "quasar";
import { ref, computed, watch } from "vue";

const columns = [
  {
    name: "date",
    required: true,
    label: "Date",
    align: "left",
    field: row => row.date,
    format: val => `${val}`,
    sortable: true
  },
  {
    name: "post",
    align: "center",
    label: "Post",
    field: "post",
    sortable: true
  },
  {
    name: "category",
    align: "right",
    label: "Category",
    field: "category",
    sortable: true
  }
];

const rows = [
  {
    date: "2024.08.27",
    category: "系務活動",
    post: "賀",
    description: `
    服經系周立恒同學自創品牌CHOW des HOMME<br />
    獲選2024 臺北時裝週 SS25 品牌秀，<br />
    CHOW des HOMME獨立秀於10月18日在官方場地松山文創園區辦理。
    `
  },
  {
    date: "2024.08.15",
    category: "系務活動",
    post: "賀",
    description: `
    謝建騰助理教授通過教育部人文與藝術及設計學門教學實踐研究計畫-<br />
    結合問題式導向學習(PBL)與BOPPPS教學設計
    探討服裝設計師對材料的基本素養
    `
  },
  {
    date: "2024.08.15",
    category: "系務活動",
    post: "賀",
    description: `
    黃崇信助理教授通過教育部人文與藝術及設計學門教學實踐研究計畫-<br />
    全球化趨勢下強化在地特色之教學研究
    `
  },
  {
    date: "2024.08.15",
    category: "系務活動",
    post: "2024世界皮革創意設計大賽",
    description: `
    服裝設計組(郭昶均)<br />
    鞋類設計組(王楷茵)<br />
    配飾設計組(張芸禎)<br />
    決賽入圍
    `
  },
  {
    date: "2024.07.18",
    category: "系務活動",
    post: "世界大學的典範University of Cambridge",
    description: `
    <iframe src="https://www.instagram.com/p/C9c3kF4yrB7/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2024.07.18",
    category: "系務活動",
    post: "倫敦深度移地教學",
    description: `
    <iframe src="https://www.instagram.com/p/C9Wq43pPw69/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2024.07.18",
    category: "系務活動",
    post: "倫敦 Greenwich",
    description: `
    <iframe src="https://www.instagram.com/p/C9gwWVSPiuM/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2024.06.04",
    category: "系務活動",
    post: "輝達NVIDA 全球合作夥伴大學",
    description: `
    <iframe src="https://www.instagram.com/p/C7x2-ORvBsk/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2024.03.13",
    category: "系務活動",
    post: "巴黎深度移地教學",
    description: `
    <iframe src="https://www.instagram.com/p/C4dLVDcP-wM/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2024.03.07",
    category: "設計工作營",
    post: "設計探索體驗工作營",
    description: `
    <iframe src="https://instagram.com/p/C4NnaryvOGH/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.08.06",
    category: "系務活動",
    post: "新生線上說明會 08/20",
    description: `
    <a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3465_1981199_67064.jpg"><img width="100%" src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3465_1981199_67064.jpg"></a> 
    `
  },
  {
    date: "2023.06.01",
    category: "學生創作",
    post: "新一代設計展",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2Fpfbid0gcqJrLXwXtysDyeQjHUKCjnM9yYaksD1HQhuj1Er9g6EwxcvDbo1CZZozRgxKjF8l&show_text=true&width=500" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2023.05.26",
    category: "學生創作",
    post: "新一代設計展",
    description: `
    <iframe src="https://www.instagram.com/p/CstdO2xNvf3/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.05.23",
    category: "學生創作",
    post: "新一代設計展",
    description: `
    <iframe src="https://www.instagram.com/p/CslRtAQPG5k/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.05.09",
    category: "設計工作營",
    post: "時裝週 造型構想與執行",
    description: `
    <iframe src="https://www.instagram.com/p/CsBR-d5SnX0/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.04.28",
    category: "學生創作",
    post: "數位時尚",
    description: `
    <iframe src="https://www.instagram.com/reel/CrlWkHjs590/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.04.27",
    category: "系務活動",
    post: "單獨招生",
    description: `
    <iframe src="https://www.instagram.com/reel/Cri4_qorbEb/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.03.31",
    category: "設計工作營",
    post: "Marangoni設計學院",
    description: `
    <iframe src="https://www.instagram.com/p/CqbwzXLSPv3/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.03.29",
    category: "臺北時裝週",
    post: "最佳展演學校獎",
    description: `
    <iframe src="https://www.instagram.com/p/CqYFDquyt8h/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.03.22",
    category: "臺北時裝週",
    post:
      "服飾設計與經營學系三年級周立恒同學獲選2023AW 臺北時裝週品牌秀發表設計作品",
    description: `
    服經系周立恒同學是臺北時裝週最年輕的品牌設計師，也是全國唯一在學
    學生獲選發表品牌秀的設計師。他所主理的服飾品牌 CHOW des HOMME，
    以雋永的現代感為特色，期望在追求時尚前衛設計感的同時，又不失百變
    穿搭的趣味性。

    周立恒 認為一件好的設計作品，不僅僅體現在 T 台走秀，而是讓更多人
    接受並穿戴。

    此次作品發表特別結合配件品牌 Zeit，不盲目追逐主流，金屬質感呈現
    未來感和獨特洗鍊風格，展現這個世代對於飾品的全新跨界美感。

    臺北時裝週 AW23 品牌秀
    ZEIT x CHOW des HOMME

    日期時間：112/3/27（一）
    10:30-11:00 入場
    11:00-11:30 開秀

    地點：松菸四號倉庫｜台北市信義區光復南路133號
    <a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3382_6348106_87143.jpg"><img width="100%" src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3382_6348106_87143.jpg"></a> 
    <a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3383_9828061_87161.jpg"><img width="100%" src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3383_9828061_87161.jpg"></a> 
    <a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3384_7277330_87178.jpg"><img width="100%" src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3384_7277330_87178.jpg"></a> 
    `
  },
  {
    date: "2023.03.16",
    category: "系務活動",
    post: "單獨招生",
    description: `
    <a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3379_8222877_69929.jpg"><img width="100%" src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3379_8222877_69929.jpg"></a> 
    `
  },
  {
    date: "2023.01.24",
    category: "系務活動",
    post: "Tokyo 這個充滿魅力的國際時尚城市",
    description: `
    <iframe src="https://www.instagram.com/p/CnzUozIS8UI/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.01.23",
    category: "系務活動",
    post: "Designer of dreams",
    description: `
    <iframe src="https://www.instagram.com/p/CnwcDrEy0fH/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.01.18",
    category: "系務活動",
    post: "日本文化服裝學院合作",
    description: `
    <iframe src="https://www.instagram.com/p/Cnj9LLry8V7/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2023.01.16",
    category: "系務活動",
    post: "2023 東京移地教學",
    description: `
    <iframe src="https://www.instagram.com/reel/Cne0uW3IdYJ/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2022.12.18",
    category: "系務活動",
    post: "亞洲時尚最前線「東京」",
    description: `
    <iframe src="https://www.instagram.com/p/CmSi2yXSMnK/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2022.11.13",
    category: "學生創作",
    post: "runway 服裝秀",
    description: `
    <iframe src="https://www.instagram.com/p/Ck4TgcJSU7T/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2022.10.31",
    category: "系務活動",
    post: "甜上癮 HOOKED FASHION SHOW",
    description: `
    2022/11/12(六)
    FASHION SHOW 時裝走秀
    東九道之驛-旗糖農創園區-8號倉庫
    高雄市旗山區忠孝街33號
    報到 13:00 - 13:30
    走秀 13:30 - 15:00
    <a target="_blank" href="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3080_3204668_23837.jpg"><img width="100%" src="https://fdm.kh.usc.edu.tw/var/file/35/1035/randimg/mobileadv_3080_3204668_23837.jpg"></a> 
    `
  },
  {
    date: "2022.07.22",
    category: "設計工作營",
    post: "新生工作營（台北場）",
    description: `
    <iframe src="https://www.instagram.com/p/CgT6_iBFGtJ/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2021.11.22",
    category: "學生創作",
    post: "高雄時尚大賞",
    description: `
    <iframe src="https://www.instagram.com/p/CWkfGLWvgqs/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2021.11.20",
    category: "比賽獲獎",
    post: "海峽兩岸服裝設計比賽",
    description: `
    <iframe src="https://www.instagram.com/p/CWe84u0PaRB/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2021.11.05",
    category: "系務活動",
    post: "一年一度的迎新活動來臨了🎉",
    description: `
    一年一度的迎新活動來臨了🎉
    歡迎新生加入我們FDM 大家庭✨✨
    -
    這次因為疫情取消了宿營兩天一夜，但也澆不熄新生們的熱情🔥
    因應萬聖節，各個工作人員、新生都全副武裝🎃
    每個遊戲關卡都精心規劃讓新生們體驗到不同的樂趣，還有晚上的烤肉晚會🥳
    透過迎新不僅讓新生們認識同屆朋友！也可以認識許多學長姊們！
    謝謝參與的老師、工作人員以及新生們，辛苦了✨
    <iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fvideos%2F396394621821308%2F&show_text=false&width=476&t=0" width="100%" height="476" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>`
  },
  {
    date: "2021.09.24",
    category: "臺北時裝週",
    post: "臺北時裝週 Taipei fashion week SS22",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F4733037866747607&show_text=true&width=500" width="100%" height="659" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2021.09.24",
    category: "比賽獲獎",
    post: "2021 ’’ 第二屆世界皮革大賽 ‘‘",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F4732552413462819&show_text=true&width=500" width="100%" height="480" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2021.03.28",
    category: "學生創作",
    post: "畢業靜態展",
    description: `
    <iframe src="https://www.instagram.com/p/CM9Bh5XDp_Q/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2021.03.26",
    category: "系務活動",
    post: "FDM時裝秀2021衣裳決賽",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F4171223876262345&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2021.03.18",
    category: "臺北時裝週",
    post:
      "臺北時裝週 官方評審也挑選出25套推薦作品，邀請大家為喜愛的設計新秀或優秀作品投票，之後會公布哪些可以參加明年台北時裝週設計新秀。",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F4149678535083546&width=500&show_text=true&height=749&appId" width="100%" height="749" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2021.03.16",
    category: "臺北時裝週",
    post: "作品發表",
    description: `
    <iframe src="https://www.instagram.com/p/CMe75Uajj6A/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2021.03.15",
    category: "臺北時裝週",
    post: "作品發表",
    description: `
    <iframe src="https://www.instagram.com/p/CMbEIPjj9ju/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2021.03.11",
    category: "臺北時裝週",
    post:
      "⚠️3/12敬請期待服飾設計與經營學系⚠️臺北時裝週 Taipei Fashion Week 大秀直播預告‼️",
    description:
      '<iframe src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fvideos%2F4130078687043531%2F&show_text=true&width=476" width="100%" height="591" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>'
  },
  {
    date: "2021.01.21",
    category: "系務活動",
    post:
      "FASHION DESIGN AND MERCHANDISING/服飾設計Fashion Show 2021期末發表 指導老師： @ednamode_tseng ",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3989280824456652&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.12.20",
    category: "學生創作",
    post: "服飾設計與經營學系畢製海報/名牌 designer: @kun_lin_xie ",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3907387462645989&width=500&show_text=true&height=382&appId" width="100%" height="382" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.12.18",
    category: "系務活動",
    post: "暖冬同樂",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3902639243120811&width=500&show_text=true&height=749&appId" width="100%" height="749" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.12.10",
    category: "系務活動",
    post: "「 舊物市集 」 歡迎大家約好朋友們一起來挖寶 ！！！🛒",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3880951928622876&width=500&show_text=true&height=674&appId" width="100%" height="674" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.11.29",
    category: "比賽獲獎",
    post: "陶瓷服裝創意大賽",
    description: `
    <iframe src="https://www.instagram.com/p/CIKlh_XjE5r/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2020.11.20",
    category: "學生創作",
    post: "InnoFashion",
    description: `
    <iframe src="https://www.instagram.com/p/CHzK-zKjPwc/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2020.11.07",
    category: "學生創作",
    post: "InnoFashion",
    description: `
    <iframe src="https://www.instagram.com/p/CHRX3vSDYdD/embed/captioned" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    `
  },
  {
    date: "2020.10.29",
    category: "設計工作營",
    post: "藍染講座暨工作體驗營",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3764726150245455&width=500&show_text=true&height=755&appId" width="100%" height="755" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.10.22",
    category: "比賽獲獎",
    post: "「2020 Look台味競賽」",
    description:
      '<iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fvideos%2F3395090840716785%2F&show_text=true&width=560" width="100%" height="429" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>'
  },
  {
    date: "2020.10.17",
    category: "比賽獲獎",
    post: "「2020聚陽人文發展教育基金會 衣舊要愛創意設計競賽」",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3730082940376443&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.09.08",
    category: "比賽獲獎",
    post: "2020第一屆世界皮革創意設計大賽",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3608411672543571&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.07.21",
    category: "設計工作營",
    post: "FDM暑期工作營 流行服飾插畫手繪創作✨",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3458542434197163&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.06.30",
    category: "設計工作營",
    post: "FDM暑期工作營 服裝設計工作營 X 吳日云服裝設計師",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3397600653624675&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.06.30",
    category: "設計工作營",
    post: "FDM暑期工作營 袋包設計工作營X陳俞方袋包設計師",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3397555803629160&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.06.30",
    category: "設計工作營",
    post: "FDM暑期工作營 IG品牌經營工作營 X 楊攸仁(大仁哥)",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3397534506964623&width=500&show_text=true&height=729&appId" width="100%" height="729" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.06.20",
    category: "比賽獲獎",
    post: "本系施亮熙同學入圍2020台味服裝暨配件設計大賞決賽🎉",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3368641813187226&width=500&show_text=true&height=749&appId" width="100%" height="749" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.04.22",
    category: "學生創作",
    post: "2020 Apr. 防疫口罩妝容",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3215105351874207&width=500&show_text=true&height=770&appId" width="100%" height="770" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.04.19",
    category: "系務活動",
    post: "2020衣賞設計競賽",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3208094595908616&width=500&show_text=true&height=708&appId" width="100%" height="708" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.04.18",
    category: "系務活動",
    post: "2020衣賞設計競賽",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3205438030040935&width=500&show_text=true&height=708&appId" width="100%" height="708" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  },
  {
    date: "2020.02.21",
    category: "比賽獲獎",
    post: "第九屆儒鴻服裝設計競賽",
    description:
      '<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ffdmshihchien%2Fposts%2F3072992259418851&width=500&show_text=true&height=693&appId" width="100%" height="693" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>'
  }
];

export default {
  props: {
    tab: String
  },
  setup(props) {
    const $q = useQuasar();

    function getItemsPerPage() {
      if ($q.screen.lt.sm) {
        return 2;
      }
      if ($q.screen.lt.md) {
        return 4;
      }
      return 6;
    }

    const pagination = ref({
      page: 1,
      rowsPerPage: getItemsPerPage()
    });

    watch(
      () => $q.screen.name,
      () => {
        pagination.value.rowsPerPage = getItemsPerPage();
      }
    );

    return {
      rows,
      filter: ref(props.tab === "所有訊息" ? "" : props.tab),
      pagination,
      columns,
      rowsPerPageOptions: computed(() => {
        return $q.screen.gt.xs ? ($q.screen.gt.sm ? [2, 4, 6] : [2, 4]) : [2];
      })
    };
  }
};
</script>
