<template>
  <q-page>
    <div class="news">
      <!-- <h1>This is an news page</h1> -->
      <NewsTabPanel />
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
import NewsTabPanel from "@/components/NewsTabPanel.vue";

export default {
  setup() {
    return {};
  },
  name: "Award",
  components: {
    NewsTabPanel
  }
};
</script>
